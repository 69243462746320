* { margin: 0; padding: 0; box-sizing: border-box; }
h1,h2,h3 {font-family: 'NanumSquare', sans-serif}
li { list-style: none; }
a { text-decoration: none; }
img { border: 0; max-width: 100%; vertical-align: top}
body { font-family: 'Noto Sans KR', sans-serif; background: #2e2e48;}

input[type="date"] {position: relative; height: 30px; padding: 0 15px 0 5px; border: 1px solid #575782; color: #fff; background: #2e2e48;}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {display: none;}
input[type="date"]::-webkit-calendar-picker-indicator {position: absolute; top: 2px; right: 4px; width: 20px; height: 20px; color: transparent; background: url(./images/cal.png) no-repeat right center / 15px;}
input[type="datetime-local"] {position: relative; height: 30px; padding: 0 15px 0 5px; border: 1px solid #575782; color: #fff; background: #2e2e48;}
input[type="datetime-local"]::-webkit-calendar-picker-indicator {position: absolute; top: 2px; right: 4px; width: 20px; height: 20px; color: transparent; background: url(./images/cal.png) no-repeat right center / 15px;}

/* popup */
.alertify .ajs-dialog { border-radius: 20px; color: #fff; background-color:#53537c}
.alertify .ajs-footer { border-radius: 0 0 20px 20px; background-color: transparent;}
.alertify.ajs-movable:not(.ajs-maximized) .ajs-header { border-radius: 20px 20px 0 0; border: 3px solid #53537c; border-radius: 15px; background-color: #353559;}
.ajs-primary { margin-bottom: 15px; text-align: center!important}
.ajs-primary button { border-radius: 20px; background: #eba300; border: none; color: #292519; }
.alertify .ajs-body .ajs-content { text-align: center; padding: 20px 0; }
.alertify .ajs-footer .ajs-buttons .ajs-button { min-width: 100px!important }