/* @charset "utf-8"; */
/* CSS Document */

/*  login  */
.login_wrap { width: min(100%, 1280px); height: 956px; margin: 0 auto; border-radius: 20px; }

.right { width: max(85%, calc(15% - 160px)); border: 1px solid #353550; }
.right_top {display: flex; justify-content: flex-end; text-indent: -9000px; margin-right: 30px; padding-top: 20px; margin-bottom: 30px;}
.all-title {width: 180px; height: 20px; text-indent: -9000px; background: url(./images/logo.png) no-repeat 0 0 / auto 15px;}

.login { width: 100%; height: 100%; display: flex; flex-wrap: wrap; justify-content: center; align-items: center;}
.login_top {position: absolute; top: 220px; left: 0; width: 100%; display: flex; justify-content: center;}
.login_top .all-title {zoom: 2;}
.login_contents { width: 400px; height: 360px; display: flex; border-radius: 20px; justify-content: center; align-content: center; flex-direction: column; background: #3a3a60; box-shadow: inset 0 0 5px #75759950;}
.login_title { font-size: 36px; font-weight: 500; text-align: center; color: #fff; font-family: 'Poppins', sans-serif; margin-bottom: 40px; text-transform: uppercase; opacity: .75;}

.login input { width: 320px; height: 40px; border: 2px solid #757599; border-radius: 20px; text-indent: 20px; background: #252541; }
.login input:first-child { margin-bottom: 20px; }

.login a { display: block; font-size: 16px; margin: 0 auto; font-weight: 600; color: #292519; width: 150px; height: 38px; line-height: 38px; text-align: center; background: #eba300; border-radius: 20px; }

.login_contents form { margin-bottom: 55px; display: flex; flex-wrap: wrap; justify-content: center; color: #fff; }
.login_contents input { color: #fff; }


/*  left bar  */
.contents_wrap { width: max(100%, 1280px); height: 100vh; margin: 0 auto; background: #383854; display: flex; flex-wrap: wrap}

.left {position: relative; width: max(15%, 160px); height: 100vh; background: #252541; border-radius: 0 20px 20px 0;}
.main_title { font-size: 32px; font-weight: 700; color: #fff; letter-spacing: 5px; text-align: center; padding-top: 75px; margin-bottom: 67px; }
.left_top nav { width: 80%; margin: 0 auto; }
.left_top nav > ul > li { width: 100%; height: 40px; line-height: 40px; border-radius: 10px; margin-bottom: 30px; text-indent: 20px; cursor: pointer;}
.left_top nav > ul > li.open { background: #475be8; }
.left_top nav > ul > li:last-child { margin-bottom: 0; }
.left_top nav > ul > li > a { display: block; font-size: 14px; font-family: 'Poppins', sans-serif; font-weight: 400; color: #fff; }
.left_top nav > ul > li > a > img { margin: 15px 10px 0 0; }

.left_btm {position: absolute; bottom: 15%; left: 10%; width: 80%;}
.left_btm ul li { display: flex; align-items: center; height: 40px; text-indent: 10px; margin-bottom: 20px; padding-left: 10px; cursor: pointer;}
.left_btm ul li.open { background: #475be8; ; border-radius: 10px;}
.left_btm ul li:last-child { margin-bottom: 0px; }
.left_btm ul li a { font-family: 'Manrope', sans-serif; display: block; font-size: 14px; font-weight: 400; color: #fff; }
.left_btm_img { width: 25px; height: 25px;  background: url('./images/left_btm.png')no-repeat; background-size: auto 25px; }
.admin_link .left_btm_img { background-position: -25px 0px }
.logout_link .left_btm_img { background-position: -50px 0px }


/*  Status  */
.signal label::before {content: ''; display: block; width: 18px; height: 18px; border-radius: 50%; border: 1px solid #151529}
.signal label.work::before {background-color: #2ac670;}
.signal label.stop::before {background-color: yellow}
.signal label.error::before {background-color: #ff6252;}
.signal label.offline::before {background-color: grey;}

.status_contents { padding: 0 20px; display:flex; flex-wrap: wrap; justify-content: flex-end; }
.status_contents_top { background: #252541; width: 550px; height: 42px; border-radius: 10px; margin-bottom: 10px; }
.status_contents_top dl { display: flex; justify-content: center; font-size: 16px; font-weight: 400; color: #fff; line-height: 40px;}
.status_contents_top dl dd {display: flex; align-items: center; padding: 0 20px; column-gap: 5px;}

.status_contents_btm { width: 100%; height: 100%; margin: 0 auto; padding: 15px; border-radius: 10px; background: #252541; overflow-y: auto; }
.status_contents_btm ul { display:grid; grid-template-rows:1fr; grid-template-columns:repeat(10, 1fr); justify-content: space-between; gap: 10px; overflow: hidden;}
.status_contents_btm ul li { display: flex; flex-direction: column; align-items: center; row-gap: 10px; padding: 15px 0; border: 1px solid #676788; border-radius: 10px;  background: #38385450; cursor: pointer;}
.status_contents_btm ul li:hover {background: #383854}
.status_contents_btm span { font-size: 16px; font-family: 'Bebas Neue', cursive; color: #fff; white-space: nowrap;}


/*  monitoring_wrap  */
.monitor .search {display: flex; justify-content: center; margin: 0 15px; border-radius: 10px; background: #252541;}
.monitor .search li { width: 200px; height: 50px; border-radius: 15px; text-indent: 25px}
.monitor .search img { margin: 17px 5px 0 0; }
.monitor .search input { border: none; background: none; width: 70%; height: 100%; overflow: auto; appearance: none; text-indent: 10px; font-size: 15px; font-weight: 700; color: #fff; }
.monitor .search input::placeholder {color: #fff;}

.monitor_contents { width: 100%; height: 840px; padding: 15px; border-radius: 10px; display: grid; grid-template-columns: calc(35% - 30px) 30% 35%; grid-template-rows: repeat(2, 1fr ); gap: 15px;}
.monitor_contents > div {background: #252541; border-radius: 15px; padding: 5px; }
.monitor_contents_first { grid-column: 1 / 2; grid-row: 1 / 3; }
.monitor_contents_first .monitor_contents_top {margin-bottom: 10px;}
.monitor_contents_second { grid-column: 2 / 3; grid-row: 1 / 3; }
.monitor_contents_third { grid-column: 3 / 4; grid-row: 1 / 2;}
.monitor_contents_foutth { grid-column: 3 / 4; grid-row: 2 / 3;}
.monitor_contents_top { width: 100%; height: 45px; background: #38385475; border: 1px solid #676788; border-radius: 10px; display: flex; flex-wrap: wrap; justify-content: center; align-items: center; margin-bottom: 25px; }
.monitor_contents_title { font-size: 20px; font-weight: 700; color: #fff}

.monitor_contents_btm { display: flex; flex-wrap: wrap; align-items: flex-start; font-size: 14px; color: #fff}
.monitor_contents_btm ul li {display: flex; align-items: center; column-gap: 10px; margin: 10px 0; padding: 5px; border: 1px solid #575782; border-radius: 15px;}
.monitor_contents_btm_left_ul { width: calc(50% - 10px); margin-right: 10px;}
.monitor_contents_btm_left_ul li {justify-content: flex-end}
.monitor_contents_btm_rigjt_ul { width: 50%;}

.monitor_contents_box { width: 100%; display: flex; flex-wrap: wrap; justify-content: space-between; padding: 0 20px; margin-bottom: 7px; }
.monitor_contents_box_title { width: 40%; height: 25px; font-size: 14px; color: #fff; text-align: center; border: 1px solid #2f3676; border-radius: 20px; background: #475be825; cursor: pointer; }
.monitor_contents_box_title.on {background: #475be8}
.monitor_contents_box_title.none {background: transparent; background: #38385475; border: 1px solid #676788; cursor: default; width:100%}
.monitor_contents_box_number { width: calc(60% - 10px); height: 25px; font-size: 14px; color: #fff; font-weight: 300; border: 1px solid #575782; border-radius: 20px; text-align: right; padding: 0 15px 0 0; }
.monitor_contents_box:last-child .monitor_contents_box_number {width:100%; text-align: center; margin-top: 5px; border: none;}

/*날짜 넣으면 수정하기*/
.monitor_date { display: flex; flex-wrap: wrap; align-items: center; justify-content: center; column-gap: 10px; width: 100%; text-align: center; }
.monitor_date input {border-radius: 5px;}
.monitor_graph { width: 100%; border-radius: 10px;}
.sub_title { margin: -10px 0 10px; padding: 5px 0; border-radius: 10px; color: #fff; font-family: 'NanumSquare', sans-serif; font-size: 18px; font-weight: 700; text-align: center; background: #475be8;}

.minMax {display: flex; flex-wrap: wrap; justify-content: center; align-items: center; column-gap: 10px; margin-bottom: 20px;}
.minMax span {display: flex; justify-content: center; align-items: center; column-gap: 10px; max-width: 200px; background: #38385475; border: 1px solid #676788; border-radius: 10px; color: #fff;}
.minMax label {width: 50px; text-align: center;}
.minMax input {width: calc(100% - 80px);  height: 30px; color: #fff; border: none; text-align: center; background-color: transparent; }

/*  error  */
.error_contents { padding: 0 20px;}
.table_wrap { width: 100%; height: 720px; background: #252541; padding: 15px; border-radius: 10px; overflow-y: auto;}
.table_wrap ul { display: flex; flex-wrap: wrap; }
.table_wrap ul li { height: 40px; line-height: 40px;  }
.table_wrap table { width: 100%; border-radius: 10px; border-spacing: 0; overflow: hidden; }
.table_wrap table thead td { text-align: center; height: 40px; line-height: 40px; font-size: 14px; font-weight: 300; color: #fff; border-bottom: 1px solid #676788; }
.table_wrap table tbody td { text-align: center; height: 40px; line-height: 40px; font-size: 14px; font-weight: 300; color: #fff; border-bottom: 1px solid #353550;}


/*  sms  */
.sns_contents { padding: 0 20px; display: flex; flex-wrap: wrap; justify-content: flex-end;}

.add_box { width: 100%; margin-bottom: 15px; display: flex; flex-wrap: wrap; justify-content: right; }
.add_box button { width: 60px; height: 40px; background: #eba300; color: #292519; font-weight: 700; border-radius: 15px; border: 0; cursor: pointer; text-align: center; text-indent: 0 }
.add_box input { width: 180px; height: 40px; border: 2px solid #757599; background: none; text-indent: 20px; border-radius: 15px; margin-right: 10px; color: #fff; background: #252541}
.add_box input::placeholder { color: #eee; font-size: 12px; font-weight: 300; }

/*  account  */
.account table { width: 100%; border-radius: 10px; border-spacing: 0; overflow: hidden; }
.account table tr td button { border: none; margin: 10px 0 0 5px; cursor: pointer; }


/*  admin  */
.idt_btn button { width: 70px; height: 30px; background: #f07958; color: #fff; border-radius: 20px; margin: 2px 0}
.idt_btn button.delete { background: none; border: 1px solid #f07958!important }
.idtable tr td input { background: none; width: calc(100% - 10px); border : 1px solid #999; height: 30px; text-indent: 15px; margin-top: 5px; color: #fff; background: #353550;}
.idtable tr td input:read-only {border:none; background: transparent; pointer-events: none;}

#apex {color: black;}


.red {background-color: #ff6252;border-radius: 50%; border: 1px solid #151529}
.green{background-color: #2ac670;border-radius: 50%; border: 1px solid #151529}
.gray{background-color: #dcdcdc;border-radius: 50%; border: 1px solid #151529}

.mm {color: white}